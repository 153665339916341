<template>
	<section class="container">
		<div>
			<h1>Ændr depotværdi</h1>
		</div>
		<div class="row mt-2 mb-2">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row">
			<b-form @submit.prevent="onSubmit">
				<div class="col-lg-6 col-12 lb pb-1 mt-1">
					<div class="row">
						<div class="col-12">
							<label><b>Værdi</b></label>
							<InputNumber v-model="custodianValue.value"> </InputNumber>
						</div>

						<div class="col-12">
							<b> Dato </b>
							<InputDate v-model="custodianValue.date"> </InputDate>
						</div>
						<div class="col-12">
							<label><b>Kommentar</b></label>
							<InputText v-model="custodianValue.comment"> </InputText>
						</div>
					</div>
				</div>

				<div class="row mb-5 mt-3">
					<div class="col-12">
						<BaseActionButton class="btn-add" v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="signupButton">
							{{ $t('global.create') }}
						</BaseActionButton>
					</div>
				</div>
			</b-form>
		</div>
	</section>
</template>

<script>
import InputText from '@/modules/global/atomComponents/InputText'
import InputCheck from '@/modules/global/atomComponents/InputCheck'
import InputDate from '@/modules/global/atomComponents/InputDate'
import InputNumber from '@/modules/global/atomComponents/InputNumber'
import Supplier from '@/modules/global/components/Supplier'

export default {
	name: 'TransferEdit',
	components: {
		InputText,
		InputCheck,
		InputDate,
		InputNumber,
		Supplier,
	},
	async mounted() {
		console.log('Called')
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			clicked: false,
			disabled: false,
		}
	},
	methods: {
		async onSubmit() {
			this.clicked = true
			try {
				var companyId = this.companyId
				var employeeId = this.employeeId
				var id = this.id
				var result = await this.$store.dispatch('employeeVuex/updateEmployeePolicy', { id, companyId, employeeId, data: this.employeePolicy })
				this.toast('Success', 'En overførsel nu oprettet', true)
				this.$router.push({
					name: 'CompanyEmployeeIndividual',
					params: { companyId: this.companyId, employeeId: this.employeeId },
				})
			} catch (err) {
				this.toast('Error', 'Noget gik galt' + err, false)
				this.clicked = false
			}
			this.clicked = false
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		id() {
			return this.employeePolicy.id
		},
		index() {
			return this.$route.params.index
		},
		custodianValue: {
			get() {
				return this.employeePolicy.individualCondition.custodianValueList[this.index]
			},
			set(value) {
				this.employeePolicy.individualCondition.custodianValueList[this.index] = value
			},
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers'].sort((a, b) => a.name.localeCompare(b.name))
		},
	},
}
</script>

<style></style>
